import React from 'react';
import { Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import RetrievePaymentFuture from './RetrievePaymentFuture';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const handleClick = (e) => {
    e.preventDefault();
    window.location.href = process.env.REACT_APP_FRONTEND_BASE_URL + '/member-login';
}

const PaymentStatusFuture = () => {
    return (
        <div style={{ float: "left" }}>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 text-center">
                        </div>
                        <div class="col-md-7 text-center">
                            <div class="form-container">
                                <div class="col-md-12 form-group text-center" style={{ paddingTop: '20px' }}>
                                    <Elements stripe={stripePromise}>
                                        <RetrievePaymentFuture />
                                    </Elements>
                                </div>
                                {/* <div class="col-md-12 form-group text-center" style={{ paddingTop: '20px' }}>
                                    <Link className="btnAdd" style={{ padding: '5px 10px', float: 'none', margin: '0' }} to='/member-login' target='_blank'>Sign-In</Link>
                                </div> */}
                                <div class="col-md-12 form-group text-center" style={{ paddingTop: '20px' }}>
                                    <Link className="btnAdd" style={{ padding: '5px 10px', float: 'none', margin: '0' }} onClick={handleClick}>Sign-In</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentStatusFuture;